//Route
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Local Components
import Hero from "../components/Hero/Hero";

function Home() {
  return (
    <div className="Home">
      <Hero />
    </div>
  );
}

export default Home;
