import React, { Component, useState } from "react";
import parse from "html-react-parser";

//Json
import text from "../../text/text.json";

//CSS
import "bootstrap/dist/css/bootstrap.min.css";

function Chapter({ charFound }) {
  const charRecived = charFound.body;
  const charFormatted = parse(charRecived);

  return <>{charFormatted}</>;
}

export default Chapter;
