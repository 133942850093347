//Route
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./ChapterList.css";

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Json
import text from "../../text/text.json";

function ChapterList() {
  const navChars = text.char;

  return (
    <div className="Chapter">
      <>
        <Container fluid>
          <Row>
            <Col className="char-list-text" lg="6">
              <p className="text-muted">Escoge el capítulo que quieres leer.</p>
              <hr></hr>
              <ul className="char-hero-list">
                {navChars.map((navChar) => {
                  return (
                    <li>
                      <a href={navChar.id}>{navChar.navText}</a>
                    </li>
                  );
                })}
              </ul>
              <div className="scroll-gradient-chapters"></div>
            </Col>
            <Col className="char-list-img" lg="6"></Col>
          </Row>
        </Container>
      </>
    </div>
  );
}

export default ChapterList;
