//Route
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Json
import text from "../text/text.json";

//Local Components
import ChapterList from "../components/ChapterList/ChapterList";

function Chapter() {
  const navChars = text.char;

  return (
    <div className="Chapter">
      <>
        <ChapterList />
      </>
    </div>
  );
}

export default Chapter;
