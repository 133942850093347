//CSS
import "./App.css";

//Route
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Pages
import Home from "./pages/Home";
import Chapter from "./pages/Chapter";
import ChapterDetail from "./pages/ChapterDetail";

//Local Components
import MainNav from "./components/MainNav/MainNav";

function App() {
  return (
    <div className="App">
      <Router>
        <MainNav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/capitulos" element={<Chapter />} />
          <Route path="/:chapterId" element={<ChapterDetail />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
