//Route
import { useParams } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Json
import text from "../text/text.json";

//Local Components
import ChapterDetailComp from "../components/ChapterDetailComp/ChapterDetailComp";

function ChapterDetail() {
  const params = useParams();

  const charIDArr = text.char;
  const charFound = charIDArr.find((item) => item.id === params.chapterId);

  return (
    <div className="ChapterDetail">
      <ChapterDetailComp charFound={charFound} />
    </div>
  );
}

export default ChapterDetail;
