//CSS
import "./MainNav.css";
import "bootstrap/dist/css/bootstrap.min.css";

//React
import { Link } from "react-router-dom";

//Bootstrap
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";

//Icons
import { XLg } from "react-bootstrap-icons";

//Json
import text from "../../text/text.json";

function MainNav() {
  const navChars = text.char;

  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} expand={expand} className="">
          <Container fluid className="px-md-5 py-md-3">
            <Navbar.Brand href="#">
              <Link to="/">
                <h4>
                  Soluciones<span>Falsas</span>
                </h4>
              </Link>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="offcanvas-navbar">
              Capítulos
            </Navbar.Toggle>

            <Navbar.Offcanvas
              id="offcanvas-navbar"
              aria-labelledby="offcanvas-navbar"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvas-navbar">
                  <h4>
                    Soluciones<span>Falsas</span>
                  </h4>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <hr></hr>
              <Offcanvas.Body className="mb-5">
                <Nav className="flex-grow-1">
                  {navChars.map((navChar) => {
                    return (
                      <Nav.Link href={navChar.id}>{navChar.navText}</Nav.Link>
                    );
                  })}
                </Nav>
                <div className="menu-scroll-gradient"></div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default MainNav;
